// FONT FACE --INTER
@font-face {
  font-family: Inter;
  font-weight: 300;
  src: url("../fonts/Inter/static/Inter-Light.ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url("../fonts/Inter/static/Inter-Regular.ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url("../fonts/Inter/static/Inter-Medium.ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url("../fonts/Inter/static/Inter-SemiBold.ttf");
}
@font-face {
  font-family: Inter;
  font-weight: 700;
  src: url("../fonts/Inter/static/Inter-Bold.ttf");
}

// POPPINS
@font-face {
  font-family: Poppins;
  font-weight: 300;
  src: url("../fonts/Poppins/Poppins-Light.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 400;
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}

//MontserratArabic

@font-face {
  font-family: MontserratArabic;
  font-weight: 900;
  src: url("../fonts/Montserrat-Arabic/Montserrat-ArabicBlack900.otf");
}

@font-face {
  font-family: MontserratArabic;
  font-weight: 800;
  src: url("../fonts/Montserrat-Arabic/Montserrat-ArabicExtraBold800.otf");
}

@font-face {
  font-family: MontserratArabic;
  font-weight: 700;
  src: url("../fonts/Montserrat-Arabic/Montserrat-ArabicBold700.otf");
}

@font-face {
  font-family: MontserratArabic;
  font-weight: 600;
  src: url("../fonts/Montserrat-Arabic/Montserrat-ArabicSemiBold600.otf");
}

@font-face {
  font-family: MontserratArabic;
  font-weight: 500;
  src: url("../fonts/Montserrat-Arabic/Montserrat-ArabicMedium500.otf");
}

@font-face {
  font-family: MontserratArabic;
  font-weight: 400;
  src: url("../fonts/Montserrat-Arabic/Montserrat-ArabicRegular400.otf");
}

@font-face {
  font-family: MontserratArabic;
  font-weight: 300;
  src: url("../fonts/Montserrat-Arabic/Montserrat-ArabicLight300.otf");
}

//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  // @apply font-display;
}

:root {
  --font-display: Poppins;
  --font-body: Poppins;
  --font-body-arabic: MontserratArabic !important;
}

.theme-animals {
  --font-display: Inter;
  --font-body: Inter;
}
